<!-- 运维管理 现场人员 电站责任人新增 -->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleFormDR">
    <template>
      <ykc-form-item label="责任人信息" prop="responsiblePerson">
        <ykc-input
          placeholder="请输入责任人"
          maxlength="50"
          v-model="ruleForm.responsiblePerson"></ykc-input>
      </ykc-form-item>
    </template>

    <ykc-form-item label="站点信息" prop="stationIds">
      <ykc-tree
        ref="objectTreeOrg"
        :data="treeDataOrg"
        :props="operatorProps"
        @check-change="handleTreeDataOrg"></ykc-tree>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import YkcTree from '@/components/ykc-ui/tree/src/tree.vue';
  import { treeUtils, omManagementApi } from '@/service/apis';

  export default {
    components: {
      YkcTree,
    },
    data() {
      return {
        treeDataOrg: [],
        configSet: {
          label: 'userAccount',
          value: 'id',
        },
        operatorProps: {
          label: 'name',
          children: 'stationTreeVOList',
          disabled: 'disabled',
        },
        ruleForm: {
          stationIds: [],
          responsiblePerson: '',
        },
        rules: {
          responsiblePerson: [{ required: true, message: '请输入责任人信息', trigger: 'blur' }],
          stationIds: [{ required: true, message: '请选择电站ID列表', trigger: 'blur' }],
        },
      };
    },
    computed: {
      formData() {
        const formData = {
          stationIds: this.ruleForm.stationIds,
          responsiblePerson: this.ruleForm.responsiblePerson,
        };

        return formData;
      },
    },
    created() {
      this.getTreeData(); // 获取区域列表
    },
    methods: {
      /**
       * 获取站点树数据
       */
      getTreeData() {
        treeUtils.operatorStations({}).then(res => {
          this.treeDataOrg = res?.map(item => ({
            id: item.operatorId,
            name: item.operatorName || 'Unknown',
            stationTreeVOList: item.stationTreeVOList.map(childItem => ({
              id: childItem.stationId,
              name: childItem.stationName,
            })),
          }));
        });
      },

      /**
       *
       */
      handleTreeDataOrg() {
        this.ruleForm.stationIds = this.$refs.objectTreeOrg.getCheckedKeys(true) || [];
      },

      /**
       * 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       */
      submitForm() {
        console.log('this.ruleForm++++', this.ruleForm);
        return new Promise(resolve => {
          this.validateForm().then(() => {
            const params = (({ stationIds, responsiblePerson }) => ({
              stationIds,
              responsiblePerson,
            }))(this.formData);
            omManagementApi
              .stationResponsibleAdd({ ...params })
              .then(res => {
                console.log(res);
                this.$message({
                  message: '新增成功',
                  type: 'success',
                });
                resolve();
              })
              .catch(() => {});
          });
        });
      },
      /**
       *校验规则
       */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleFormDR.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style scoped lang="scss"></style>
