<!--
 * @Author: Lmi
 * @Description: 运维管理 现场人员 电站责任人列表
-->
<template>
  <scroll-layout class="station-responsible-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              v-rbac="'maintain:personnel:stationResponsible:add'"
              type="plain"
              @click="add">
              新增
            </ykc-button>
            <ykc-button
              v-rbac="'maintain:personnel:stationResponsible:export'"
              type="plain"
              @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          align="right"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          :page-size.sync="pageInfo.size"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <ykc-drawer
        :show.sync="showDrawer"
        title="电站责任人新增"
        :on-close="onAddDrawerClose"
        :before-cancel="onAddDrawerCancel">
        <template #footer>
          <div class="drawer-button-wrap">
            <template v-for="(btn, i) in drawerButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <div class="drawer-main-body" v-if="showDrawer">
          <AddOrEdit ref="addOrEdit"></AddOrEdit>
        </div>
      </ykc-drawer>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { omManagementApi } from '@/service/apis';
  import { offlineExport, code } from '@/utils';
  import AddOrEdit from './AddOrEdit.vue';

  export default {
    name: 'stationResponsibleList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        showDrawer: false,
        searchParams: {
          responsiblePerson: '',
          stationName: '',
        },
        tableTitle: '责任人列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '责任人', prop: 'responsiblePerson', minWidth: '150px' },
          { label: '电站名称', prop: 'stationNames', minWidth: '150px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                showTitle: false,
                showFooter: true,
                dialogType: 'feedback',
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestList();
              });
            },
          },
        ],
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'responsiblePerson',
            label: '责任人',
            placeholder: '请输入责任人',
          },
        ];
      },
    },
    created() {
      this.requestList();
      this.initData();
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('maintain:personnel:stationResponsible:del'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.delBarrierFun(row);
                }}>
                删除
              </ykc-button>
            ),
          },
          {
            enabled: () => code('maintain:personnel:stationResponsible:detail'),
            id: '2',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/operationManagement/scenePersonnel/stationResponsible/Detail',
                query: { responsiblePerson: row.responsiblePerson },
              });
            },
          },
        ];
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        omManagementApi
          .stationResponsibleQuery(reqParams)
          .then(res => {
            console.log(res);
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 新增
       * */
      add() {
        this.showDrawer = true;
      },
      /**
       * @desc 删除
       * */
      delBarrierFun(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '是否确认删除？删除之后无法恢复请谨慎操作',
          onConfirm: done => {
            console.log(done);
            omManagementApi
              .stationResponsibleDel({ responsiblePerson: row.responsiblePerson })
              .then(res => {
                console.log(res);
                this.$message.success('删除成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
          onCancel: done => {
            console.log(done);
            done();
          },
        });
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'station_responsible',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.requestList(searchData);
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       *
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
